<template>
  <div>
    <NoLoginNav />

    <div class="container">
      <!-- 左侧 -->
      <div class="register-content">
        <p class="register-title">注册账号</p>
        <div class="lay">
          <uInput
          ref="name"
          :sign="true"
          v-model.trim="form.nickName"
          :max="15"
          placeholder="输入昵称"
          :isPwd='false'
          :rule="rule[0]"
          @clear="form.nickName = $event">
              <template #icon>
                  <a-icon type="user" />
              </template>
          </uInput>
        </div>
        
        <div class="lay">
          <uInput
          ref="code"
          :sign="true"
          :max="11"
          v-model="form.regMobile"
          placeholder="输入手机号码"
          :isPwd='false'
          :rule="rule[1]"
          @blur="verify"
          @clear="form.regMobile = $event">
              <template #icon>
                  <a-icon type="mobile" />
              </template>
          </uInput>
        </div>

        <div class="lay">
          <uInput
          :sign="true"
          v-model="form.code"
          :hasClear="false"
          max="6"
          :isPwd='false'
          placeholder="短信验证码"
          :rule="rule[2]">
              <span :class="switchCode && 'verify-code'" @click="sendMsg">{{count === 60 ? '发送验证码' : `${count}秒后重发`}}</span>
              <template #icon>
                  <a-icon type="message" />
              </template>
          </uInput>
        </div>

        <div class="lay">
          <uInput
          :sign="true"
          v-model="form.userName"
          placeholder="使用者姓名"
          :isPwd='false'
          :rule="rule[3]"
          @clear="form.userName = $event">
              <template #icon>
                  <a-icon type="solution" />
              </template>
          </uInput>
        </div>

        <div class="lay">
          <uInput
          type="password"
          max="20"
          :sign="true"
          v-model="form.password"
          placeholder="设置6至20位登录密码"
          :isPwd='false'
          :rule="rule[4]"
          @clear="form.password = $event">
              <template #icon>
                  <a-icon type="lock" />
              </template>
          </uInput>
        </div>
        
        <!-- 协议 -->
        <div class="lay">
          <div class="agreement"><a-icon type="check" :class="agreementCheck && 'check'" @click="agreementCheck = !agreementCheck"/>
          阅读并同意《<a @click="visible =true">91再生用户服务协议</a>》
          </div>
        </div>

        <div class="lay">
          <uButton @click.native="register">注册</uButton>
        </div>
      </div>

      <!-- 右侧 -->
      <div class="register-info">
        <p class="info-login">已有账号，<router-link to="/login">立即登录</router-link></p>
        <p class="info-title">注册须知</p>
        <p class="info">
          “91再生网”交易平台依据《电子商务法》及税法规定，平台交易的个人用户需通过平台注册成为个体工商户，或已注册企业的需进行亮照交易，所有平台上交易的订单都要纳入税收管理体系。平台用户系税收管理体系的一部分，存在成为税务核实查询手段中作为问询客体的可能性。“91再生网”作为卖方委托的办理人员，按照相关要求，会对平台业务的交易资料、经营情况进行全流程证据保留，望用户在平台交易中保证业务的真实性，如收到相关协查通知请给予积极配合、如实反馈。
        </p>
        <b>如需帮助，请拨打：<span>0571-56612345</span></b>

        <a-divider></a-divider>

        <p class="info-title">开店入驻流程</p>

        <div class="l-flex">
          <div class="item check">
            <p><a-icon type="user" /></p>
            <span>注册账号</span>
          </div>
          <a-icon type="arrow-right" class="arrow"/>
          <div class="item">
            <p><a-icon type="idcard" /></p>
            <span>个人验证</span>
          </div>
          <a-icon type="arrow-right" class="arrow"/>
          <div class="item">
            <p><a-icon type="file-text" /></p>
            <span>工商申请</span>
          </div>
          <a-icon type="arrow-right" class="arrow"/>
          <div class="item">
            <p><a-icon type="shop" /></p>
            <span>开店成功</span>
          </div>
        </div>
      </div>
    </div>
    <FootMain />

    <a-modal
      :visible="visible"
      centered
      title="服务协议"
      @cancel="visible=false"
      :footer="null"
      width="800px"
    >
    <body class="body">
          <h2>《 91再生网络服务使用协议 》</h2>
          <div>
              <h3>一、注册提示</h3>
              <h4>1.1</h4>
              <p>91再生同意按照本协议的规定及其不时发布的操作规则提供基于互联网以及移动网的相关服务（以下简称"网络服务"）。如无特别申明，本协议所称"网络服务"特指由91再生提供的网络服务。</p>
              <p>为获得网络服务，服务使用人（以下简称"用户"）应当同意本协议的全部条款并按照网站页面上的提示完成全部的注册程序。</p>
              <p>用户在进行注册程序过程中选中“阅读并同意协议”表示用户完全接受本协议下的全部条款并同意授权91再生使用或公开用户全部相关信息。</p>
              <p>91再生提供的各项网络服务的运作权、所有权和解释权归浙江杭州阿思拓信息科技有限公司。</p>
              <h4>1.2</h4>
              <p>用户在注册时，必须提供真实、准确的个人资料；如个人资料有任何变动，必须及时更新。</p>
              <h4>1.3</h4>
              <p>用户注册成功后，91再生将给予每位用户一个独有的用户帐号及密码。用户帐号和密码由用户独享使用和负责保管，因此用户应当对以该用户帐号进行的所有活动和事件负法律责任。</p>
              <p>用户若发现任何非法使用用户帐号或存在安全漏洞的情况，应立即通告91再生。</p>
              <h3>二 、服务内容</h3>
              <h4>2.1</h4>
              <p>91再生仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。</p>
              <h4>2.2</h4>
              <p>91再生提供的部分网络服务为收费的网络服务，用户使用收费网络服务需要向91再生支付一定的费用。如用户拒绝支付相关费用，则91再生有权不向用户提供上述收费网络服务。</p>
              <h3>三 、服务变更、中断或终止</h3>
              <h4>3.1</h4>
              <p>用户同意，鉴于网络服务的特殊性，91再生有权根据实际情况提供具体的网络服务内容，有权随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。</p>
              <p>如变更、中断或终止的网络服务属于免费网络服务，91再生无需通知用户，也无需对任何用户或任何第三方承担任何责任；</p>
              <p>如变更、中断或终止的网络服务属于收费网络服务，91再生会在变更、中断或终止之前事先通知用户，并协商相关事宜。</p>
              <h4>3.2</h4>
              <p>用户理解，91再生需要定期或不定期地对提供网络服务的平台和相关的设备进行检修或维护，如因此类情况而造成收费网络服务在合理时间内的中断，91再生无需为此承担任何责任。但91再生会尽可能就检修维护事宜事先进行通告。</p>
              <h4>3.3</h4>
              <p>如有下列任何情形之一，91再生有权随时中断或终止向用户提供本协议项下的网络服务（包括收费网络服务）而无需对用户或任何第三方承担任何责任：</p>
              <h4>（1）用户提供的个人资料不真实，不准确；</h4>
              <h4>（2）用户在使用收费网络服务时未按规定向91再生支付相应的服务费；</h4>
              <h4>（3）用户违反本协议中规定的服务使用说明。</h4>
              <h3>四 、服务使用说明</h3>
              <h4>4.1</h4>
              <p>用户应妥善使用和保管自己的用户账号和密码，不可随意将自己的用户帐号、密码转让或借予他人使用。因用户的保管疏忽或黑客行为导致用户帐号、密码遭他人非法使用，91再生不承担任何责任。</p>
              <h4>4.2</h4>
              <p>用户同意，对于用户通过网络服务上传到91再生可公开获取信息的区域的任何内容，91再生在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示上述内容（整体或部分），和/或将上述内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</p>
              <h4>4.3</h4>
              <p>用户同意91再生有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在91再生的任何页面上投放广告），并且，用户同意接受91再生通过电子邮件或手机短信等其他方式向用户发送商品促销或其他相关商业信息。</p>
              <h4>4.4</h4>
              <p>用户在使用网络服务过程中，必须遵守以下原则：</p>
              <h4>（1） 遵守中国的法律和法规；</h4>
              <h4>（2） 遵守所有与网络服务有关的网络协议、规定和程序；</h4>
              <h4>（3） 不得为任何非法目的而使用网络服务系统；</h4>
              <h4>（4） 不得以任何形式利用网络服务侵犯91再生的商业利益；</h4>
              <h4>（5） 不得利用网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；</h4>
              <h4>（6） 不得利用网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</h4>
              <h4>（7） 不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；</h4>
              <h4>（8） 不得利用网络服务系统进行任何不利于91再生的行为；</h4>
              <h4>4.5</h4>
              <p>91再生有权对用户使用网络服务的情况进行审查和监督(包括但不限于对用户存储在91再生的内容进行审核)，如用户在使用网络服务时违反任何上述规定，91再生或其授权人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户上传的内容等、暂停或终止用户使用网络服务）以减轻用户不当行为造成的影响。</p>
              <h4>4.6</h4>
              <p>91再生针对某些特定的网络服务的使用，通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容应视为本协议的一部分。用户如使用上述网络服务，视为用户也同意并遵守上述声明、通知、警示的内容。</p>
              <h3>五 、用户隐私保护</h3>
              <h4>5.1</h4>
              <p>保护用户隐私是91再生的一项基本政策。91再生保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在91再生的非公开内容，但有下列情形之一除外：</p>
              <h4>（1） 事先获得用户的明确授权；</h4>
              <h4>（2） 根据有关的法律法规要求；</h4>
              <h4>（3） 按照相关政府主管部门的要求；</h4>
              <h4>（4） 为维护社会公众的利益；</h4>
              <h4>（5） 为维护91再生的合法权益。</h4>
        <h4>&nbsp;&nbsp; 用户同意服务条款，即意味着向91再生授权。</h4>
        <h4>5.2</h4>
              <p>91再生可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与91再生同等的保护用户隐私的责任，则91再生有权将用户的注册资料等提供给该第三方。</p>
              <h4>5.3</h4>
              <p>在不透露单个用户隐私资料的前提下，91再生有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
              <h3>六 、知识产权</h3>
              <h4>6.1</h4>
              <p>91再生提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。由于上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，91再生无需向用户或任何第三方承担任何形式的责任。</p>
              <h4>6.2</h4>
              <p>91再生为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）。</p>
              <h3>七 、协议修改</h3>
              <h4>7.1</h4>
              <p>91再生有权随时修改本协议的任何条款，一旦本协议的内容发生变动，91再生将会直接在网站上公布修改之后的协议内容。该公布行为视为91再生已经通知用户修改内容，91再生也可通过其他适当方式向用户提示修改内容。</p>
              <h4>7.2</h4>
              <p>如果不同意91再生对本协议相关条款所做的修改，用户有权停止使用网络服务。但如果用户继续使用网络服务，则视为用户已接受91再生对本协议相关条款所做的修改。</p>
              <h3>八 、通知送达</h3>
              <h4>8.1</h4>
              <p>本协议项下91再生对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；自上述通知的发送之日起视为上述通知已送达收件人。</p>
              <h4>8.2</h4>
              <p>用户对于91再生的通知应当通过91再生对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</p>
              <h3>九 、违约赔偿</h3>
              <p>用户同意保障和维护91再生及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给91再生或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
              <h3>十 、免责声明</h3>
              <h4>10.1</h4>
              <p>用户明确同意其使用网络服务所存在的风险将完全由其自己承担；因其使用网络服务而产生的一切后果也由其自己承担，91再生对用户不承担任何责任。</p>
              <h4>10.2</h4>
              <p>91再生不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
              <h4>10.3</h4>
              <p>91再生不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于上述外部链接指向的不由91再生实际控制的任何网页上的内容，91再生不承担任何责任。</p>
              <h4>10.4</h4>
              <p>对于因不可抗力或91再生不能控制的原因造成的网络服务中断或其它缺陷，91再生不承担任何责任，但会尽力减少由此给用户造成的损失和影响。</p>
              <h4>10.5</h4>
              <p>用户同意，对于91再生向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，91再生无需承担任何责任：</p>
              <h4>（1） 91再生向用户免费提供的各项网络服务；</h4>
              <h4>（2） 91再生向用户赠送的任何产品或者服务；</h4>
              <h4>（3） 91再生向收费网络服务用户附赠的各种产品或者服务。</h4>
              <h3>十一 、法律效力</h3>
              <h4>11.1</h4>
              <p>本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议的规定之外，未赋予本协议各方其他权利。</p>
              <h4>11.2</h4>
              <p>如本协议中的任何条款无论因何种原因完全或部分无效或不再具有执行力，本协议的其余条款并不受此影响而依然有效并具有法律约束力。</p>
              <h4>11.3</h4>
              <p>本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如发生本协议项下的服务条款与中华人民共和国法律相抵触时，则优先适用有关法律规定。</p>
              <h3>十二 、其他规定</h3>
              <h4>12.1</h4>
              <p>本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
              <h4>12.2</h4>
              <p>本协议未尽事宜应按友好协商原则和国家有关法律法规妥善处理之。</p>
          </div>
      

  </body>
    </a-modal>
  </div>
</template>

<script>
import NoLoginNav from '@/layout/NoLoginNav'
import uInput from '@/components/uInput'
import uButton from '@/components/uButton'
import FootMain from '@/layout/FootMain'
import { save,sendSms,effect } from '@/api/user.js'
import cookie from '@/utils/js-cookie.js'

export default {
    components: {
        NoLoginNav,
        uInput,
        uButton,
        FootMain
    },
    beforeRouteEnter(to, from, next) {
        document.querySelector('body').setAttribute('style', 'background-color:#fff')
        document.querySelector('html').setAttribute('style', 'background-color:#fff')
        next()
    },
    beforeRouteLeave(to, from, next) {
        document.querySelector('body').setAttribute('style', '')
        document.querySelector('html').setAttribute('style', '')
        next()
    },
    data() {
      return {
        form: {
          nickName: '',
          regMobile: '',
          code: '',
          userName: '',
          password: ''
        },
        rule: [
          {rule: /\S/, errorMsg: '输入的用户名不能为空'},
          {rule: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, errorMsg: '输入的手机号码不正确'},
          {rule: /^\d{6}$/, errorMsg: '输入的验证码不正确'},
          {rule: /^[\u4e00-\u9fa5]{2,4}$/, errorMsg: '输入的名字不正确'},
          {rule: /^(.){6,20}$/, errorMsg: '输入的登录密码不满6位'},
        ],
        // 协议勾选
        agreementCheck: true,
        // 发送验证码
        count: 60,
        switchCode: false,
        visible: false
      }
    },
    methods: {
      // 获取验证码
      sendMsg() {
        if (this.$refs.code.verifyRule() && !this.switchCode) {
          sendSms(this.form.regMobile).then(res => {
            if (res.code === 200) {
              this.switchCode = true
              this.countDown()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      },
      countDown() {
        if (this.count > 0) {
          this.count--
          setTimeout(this.countDown, 1000)
        } else {
          this.switchCode = false
          this.count = 60
        }
      },
      verify() {
        effect({nickName: this.form.nickName, regMobile: this.form.regMobile}).then(res => {
          if (res.code !== 200) {
          console.log(res);
          if (res.message === '该用户名已经被注册') {
            this.$refs.name.ruleResult = true
            this.rule[0].errorMsg = res.message
          } else {
            this.$refs.code.ruleResult = true
            this.rule[1].errorMsg = res.message
          }
          
          }
        })
      },
      // 注册
      register() {
        if (!this.agreementCheck) {
          // 未勾选判断 ...
          return
        }
        this.rule = [
          {rule: /\S/, errorMsg: '输入的用户名不能为空'},
          {rule: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, errorMsg: '输入的手机号码不正确'},
          {rule: /^\d{6}$/, errorMsg: '输入的验证码不正确'},
          {rule: /^[\u4e00-\u9fa5]{2,4}$/, errorMsg: '输入的名字不正确'},
          {rule: /^(.){6,20}$/, errorMsg: '输入的登录密码不满6位'},
        ]
        let verify = true
        this.$children.forEach((item) => {
          let test
          item.$attrs.sign && !( test = item.verifyRule()) && (verify = verify && test)
        })
        if (this.form.nickName.length < 2) {
            this.$refs.name.ruleResult = true
            this.rule[0].errorMsg = '用户名不得少于2位'
        }
        if (!verify) return
        save(this.form, this.form.code).then(res => {
          if (res.code === 200) {
            this.$message.success(res.message)
            cookie.set('token', res.data.tokenHead + res.data.token)
            cookie.set('username', res.data.username)
            this.$router.push('/result')
          } else {
              this.$message.error(res.message)
          }
        })
      }
    }
}
</script>

<style lang="less" scoped> 
.container {
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px){
    padding: 0 15px;
  }
  margin: 0 auto;
  margin-top: 22px;
  display: flex;
  // justify-content: space-between;
  .register-content {
    margin-top: 30px;
    width: 725px;
    .register-title {
      color: #000;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #999999;
      margin-left: 24px;
      margin-bottom: 36px;
    }
    .lay {
      margin-left: 162px;
      margin-bottom: 20px;
      span {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: @primary;
      }
      .verify-code {
        color: #cccccc;
      }
    }
    .agreement {
      font-size: 14px;
      i {
        margin: 0 2px 0 12px;
        color: #ccc;
        border-radius: 50%;
        border: 2px solid #ccc;
      }
      .check {
        color: #fff;
        background: @primary;
        border: 2px solid @primary;
      }
    }
  }
  .register-info {
    width: 475px;
    padding-left: 52px;
    border-left: 1px solid @border;
    font-size: 14px;
    .info-login {
      margin: 52px 0;
      color: #666666;
    }
    .info-title {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 32px;
    }
    .info {
      text-indent: 2em;
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      margin-bottom: 12px;
    }
    b span {
      color: @error;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.l-flex {
  text-align: center;
  color: #d7d7d7;
  margin-bottom: 32px;
  .item {
    width: 92px;
  }
  .check {
    color: #666666;
  }
  .arrow {
    font-size: 18px;
    line-height: 38px;
  }
  p {
    font-size: 32px;
    margin-bottom: 14px;
    text-align: center;
  }
}
/deep/ .ant-modal-header {
  padding-left: 53px;
  border-radius: 10px 10px 0 0;
  background: #f5ae1e;
}
/deep/ .ant-modal-title {
  color: #fff;
  font-size: 20px;
}
/deep/ .ant-modal-close {
      top: -16px;
    right: -16px;
}
/deep/ .ant-modal-close-x {
      height: 41px;
    width: 41px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* right: 26px; */
    font-size: 24px;
    color: #f5ae1e;
}
/deep/ .ant-modal-content {
  border-radius: 10px;
}
/deep/ .ant-modal-body {
  padding: 0 0 10px 0;
}
.body {
  display: block;
  color: #000;
  padding: 8px;
  height: 364px;
  overflow-x: none;
  overflow-y: auto;
  h2{
    font-size:16px;
    font-weight:bold;
    text-align:center;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h3{
    font-size:14px;
    font-weight:bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  h4{
  font-size:12px;
      display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  p
  {
  text-indent: 20px;
  font-size:12px;
  line-height:22px;
      display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}
</style>